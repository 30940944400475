import "defaults.css";
import * as THREE from "three";
import * as CANNON from "cannon";
import * as dat from "dat.gui";
import { OrbitControls } from "three/examples/jsm/controls/OrbitControls.js";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader.js";
import modelUrl from "./assets/10.glb";
// import videoUrl from "./assets/textures/sintel.mp4";
import videoUrl from "./assets/vt-2.mp4";
//const faceapi = require("face-api.js");

//var contentful = require("contentful");

/*
const client = contentful.createClient({
  space: "71t85uh491hz",
  accessToken: "_jnMW81ccpmrn1O5iaC1cqVsRUsLyoUsYfiPY0hcHwE"
});
*/

var mouseX = 0;
var mouseY = 0;

var windowHalfX = window.innerWidth / 2;
var windowHalfY = window.innerHeight / 2;

let isHappy = false;
let texture;
let video;
let prevTime = 0;
let clips;
let animationMixer;
let camera;
let renderer;
let scene;
let ambientLight;
let directionalLight;
let directionalLightHelper;
let directionalLightShadowCamera;
let directionalLightShadowCameraHelper;
let controls;
let world;
let objects = [];
let walls = [];
let bodies = [];
let wallBodies = [];
let logo;
let logoBody;
let mx = 0;
let my = 0;
let gravityTimeout;
let axesHelper;
let state = {
  followMouse: false,
  updatePhysics: false,
  debug: false,
  controlsEnabled: false,
  lightHelper: false
};

let fov = 60;
let near = 0.01;
let far = 1000;

const FACTOR = 70;
const CUBE_SIZE = 10;
const ROOM_DEPTH = 20;
const WALL_WIDTH = 0.01;
const SCALE_COEFF = 0.002;
const TIME_STEP = 1 / 60;
const GRAVITY_COEFF = 100;
const ANGULAR_VELOCITY_COEFF = 30;
const VELOCITY_COEFF = 60;
const GRAVITY_RESET_TIMEOUT = 5000;
const GUI = false;

function initCamera() {
  camera = new THREE.PerspectiveCamera(fov, window.innerWidth / window.innerHeight, near, far);
  camera.updateProjectionMatrix();
  camera.position.set(0, 10, 20);
}

function initRenderer() {
  renderer = new THREE.WebGLRenderer({
    antialias: true,
    alpha: true,
    logarithmicDepthBuffer: false
  });
  renderer.shadowMap.enabled = true;
  renderer.setClearColor(0x777777);
  renderer.setSize(window.innerWidth, window.innerHeight);
  document.body.appendChild(renderer.domElement);
}

function initControls() {
  controls = new OrbitControls(camera, renderer.domElement);
  controls.target.set(0, 0, 0);
  controls.enabled = state.controlsEnabled;
  controls.update();
}

function initScene() {
  scene = new THREE.Scene();
  scene.background = new THREE.Color(0x777777);
  ambientLight = new THREE.AmbientLight(0xffffff, 0.7);
  scene.add(ambientLight);

  directionalLight = new THREE.DirectionalLight(0xffffff, 3);
  directionalLight.position.set(20, 10, 20);
  directionalLight.castShadow = true;
  directionalLightShadowCamera = directionalLight.shadow.camera;
  directionalLightShadowCamera.left = (2 * window.innerWidth) / -FACTOR;
  directionalLightShadowCamera.right = (2 * window.innerWidth) / FACTOR;
  directionalLightShadowCamera.top = (2 * window.innerHeight) / FACTOR;
  directionalLightShadowCamera.bottom = (2 * window.innerHeight) / -FACTOR;
  directionalLightShadowCamera.near = -50;
  directionalLightShadowCamera.far = 50;
  directionalLightShadowCamera.updateProjectionMatrix();
  directionalLight.shadow.mapSize.x = 2048;
  directionalLight.shadow.mapSize.y = 2048;
  scene.add(directionalLight);

  directionalLightHelper = new THREE.DirectionalLightHelper(directionalLight, 20);
  directionalLightShadowCameraHelper = new THREE.CameraHelper(directionalLight.shadow.camera);
  directionalLightShadowCameraHelper.update();

  if (state.directionalLightHelper) {
    scene.add(directionalLightHelper);
    scene.add(directionalLightShadowCameraHelper);
  }

  axesHelper = new THREE.AxesHelper(5000);
  if (state.debug) {
    scene.add(axesHelper);
  }
}

function onWindowResize() {
  camera.aspect = window.innerWidth / window.innerHeight;
  camera.updateProjectionMatrix();
  renderer.setSize(window.innerWidth, window.innerHeight);
}

function onDocumentMouseMove() {
  mouseX = (event.clientX - windowHalfX) / 100;
  mouseY = (event.clientY - windowHalfY) / 100;
}

function initEventListeners() {
  window.addEventListener("resize", onWindowResize);
  window.addEventListener("click", () => {
    video = document.getElementById("video");
    video.play();
  });
  window.addEventListener("mousemove", () => {
    video = document.getElementById("video");
    video.play();
  });
  window.addEventListener("mousemove", onDocumentMouseMove, false);
}

function loadModel() {
  return new Promise(resolve => {
    /*
  return Promise.all([
    faceapi.nets.tinyFaceDetector.loadFromUri("/models"),
    faceapi.nets.faceRecognitionNet.loadFromUri("/models"),
    faceapi.nets.faceExpressionNet.loadFromUri("/models")
  ]).then(m => {
  */
    video = document.getElementById("video");
    video.play();

    /*
    video.addEventListener("play", () => {
      setInterval(() => {
        faceapi
          .detectAllFaces(video, new faceapi.TinyFaceDetectorOptions())
          .withFaceExpressions()
          .then(d => {
            if (d.length > 0) {
              const face = d[0];
              if (face.expressions.happy > 0.8) {
                if (!isHappy) {
                  isHappy = true;
                  scene.background = new THREE.Color(0xff0000);
                  animationMixer.timeScale = 0.5;
                }
              } else {
                if (isHappy) {
                  isHappy = false;
                  scene.background = new THREE.Color(0x000000);
                  animationMixer.timeScale = 1;
                }
              }
            }
          });
      }, 100);
    });
    */

    // texture = new THREE.VideoTexture(video);
    texture = new THREE.Texture(video);

    /*
    if (navigator.mediaDevices && navigator.mediaDevices.getUserMedia) {
      var constraints = { video: { width: 1280, height: 720, facingMode: "user" } };

      navigator.mediaDevices
        .getUserMedia(constraints)
        .then(function(stream) {
          // apply the stream to the video element used in the texture

          video.srcObject = stream;
          video.play();
        })
        .catch(function(error) {
          console.error("Unable to access the camera/webcam.", error);
        });
    } else {
      console.error("MediaDevices interface not available.");
      //video.src = videoSrc;
      // fetch(videoSrc, { method: "HEAD" }).then(response => {
      //   video.src = response.url;
      // });
    }
    */

    video.src = videoUrl;
    setInterval(function() {
      if (video.readyState >= video.HAVE_CURRENT_DATA) {
        texture.needsUpdate = true;
      }
    }, 1000 / 24);

    var loader = new GLTFLoader();
    loader.load(modelUrl, function(gltf) {
      const object = gltf.scene;
      const box = new THREE.Box3().setFromObject(object);
      const size = box.getSize(new THREE.Vector3()).length();
      const center = box.getCenter(new THREE.Vector3());

      const givenCamera = object.children.find(c => c.type === "PerspectiveCamera");
      if (givenCamera) {
        givenCamera.aspect = window.innerWidth / window.innerHeight;
        camera = givenCamera;
        givenCamera.updateProjectionMatrix();
      } else {
        camera.near = size / 100;
        camera.far = size * 100;
        camera.updateProjectionMatrix();

        camera.position.copy(center);
        camera.position.x += size / 2.0;
        camera.position.y += size / 5.0;
        camera.position.z += size / 2.0;
        camera.lookAt(center);
        camera.updateProjectionMatrix();
      }

      object.position.x += object.position.x - center.x;
      object.position.y += object.position.y - center.y;
      object.position.z += object.position.z - center.z;

      controls.maxDistance = size * 10;

      scene.add(object);

      animationMixer = new THREE.AnimationMixer(object);
      clips = gltf.animations;
      clips.forEach(clip => {
        animationMixer
          .clipAction(clip)
          .reset()
          .play();
      });
      animationMixer.timeScale = 1;

      object.traverse(o => {
        if (o.type === "Mesh" && o.name !== "Plane") {
          o.material.map = texture;
        }
      });

      resolve(gltf);
    });
  });
}

function render(time) {
  requestAnimationFrame(render);

  const dt = (time - prevTime) / 1000;
  animationMixer && animationMixer.update(dt);
  prevTime = time;

  camera.position.x += (mouseX - camera.position.x) * 0.1;
  camera.position.y += (-mouseY - camera.position.y) * 0.1;

  if (controls) {
    controls.update();
  }
  if (state.lightHelper) {
    directionalLight.updateMatrixWorld();
    directionalLightHelper.position.setFromMatrixPosition(directionalLight.matrixWorld);
    directionalLightHelper.updateMatrix();
    directionalLightHelper.update();
  }
  renderer.render(scene, camera);
}

class ColorGUIHelper {
  constructor(object, prop) {
    this.object = object;
    this.prop = prop;
  }
  get value() {
    return `#${this.object[this.prop].getHexString()}`;
  }
  set value(hexString) {
    this.object[this.prop].set(hexString);
  }
}

function onLightHelperChange(value) {
  if (value) {
    scene.add(directionalLightHelper);
    scene.add(directionalLightShadowCameraHelper);
  } else {
    scene.remove(directionalLightHelper);
    scene.remove(directionalLightShadowCameraHelper);
  }
}

function onControlsEnabledChange(value) {
  if (!value) {
    controls.reset();
  }
}

function onShadowsChange(value) {
  objects.forEach(object => {
    object.castShadow = value;
    object.receiveShadow = value;
  });
}

function initGUI() {
  const gui = new dat.GUI();
  const ambientFolder = gui.addFolder("ambient light");
  ambientFolder.addColor(new ColorGUIHelper(ambientLight, "color"), "value").name("color");
  ambientFolder.add(ambientLight, "intensity", 0, 2, 0.01);
  ambientFolder.open();
  const directionalFolder = gui.addFolder("directional light");
  directionalFolder.addColor(new ColorGUIHelper(directionalLight, "color"), "value").name("color");
  directionalFolder.add(directionalLight, "intensity", 0, 2, 0.01);
  directionalFolder.add(directionalLight.position, "x", -200, 200, 1);
  directionalFolder.add(directionalLight.position, "y", -200, 200, 1);
  directionalFolder.add(directionalLight.position, "z", -200, 200, 1);
  directionalFolder.open();
  const controlsEnabledController = gui.add(controls, "enabled", false, true).name("controls");
  controlsEnabledController.onChange(onControlsEnabledChange);
  const debugController = gui.add(state, "debug", false, true);
  debugController.onChange(onDebugChange);
  const lightHelperController = gui.add(state, "lightHelper", false, true).name("light helper");
  lightHelperController.onChange(onLightHelperChange);
  gui.addColor(new ColorGUIHelper(scene, "background"), "value").name("background color");
  gui
    .add(renderer.shadowMap, "enabled", false, true)
    .name("shadows")
    .onChange(onShadowsChange);
  gui.close();
}

function onDebugChange(value) {
  if (value) {
    scene.add(axesHelper);
    scene.background = new THREE.Color(0x777777);
  } else {
    scene.remove(axesHelper);
    scene.background = new THREE.Color(0x777777);
    controls.reset();
    controls.enabled = false;
  }
}

function main() {
  initCamera();
  initRenderer();
  initScene();
  initEventListeners();
  initControls();
  loadModel().then(() => {
    if (GUI) {
      initGUI();
    }
    requestAnimationFrame(render);
  });
}

main();
